<template>
  <section class="section">
    <router-view :key="$route.name + ($route.params.id || '')"></router-view>
  </section>
</template>

<script>
export default {
  name: 'Announcements',
};
</script>

<style scoped lang="scss">
</style>
